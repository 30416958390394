var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "forgot-container" },
    [
      _c(
        "el-form",
        {
          ref: "form",
          staticClass: "card-box login-form",
          attrs: {
            "auto-complete": "on",
            model: _vm.form,
            rules: _vm.rules,
            "label-position": "left",
            "label-width": "0px",
          },
        },
        [
          _c("div", { staticClass: "logo" }, [
            _c("img", { attrs: { src: _vm.logo } }),
          ]),
          _vm.reset_password
            ? [
                _c("div", [
                  _c("div", { staticClass: "message" }, [
                    _vm._v(
                      " If a valid account was found, an email confirming your"
                    ),
                    _c("br"),
                    _vm._v(
                      " reset password will be sent to your email address. "
                    ),
                  ]),
                  _c("div", { staticClass: "footer-link" }, [
                    _c("a", { attrs: { href: "/login" } }, [
                      _vm._v("Return to login"),
                    ]),
                  ]),
                ]),
              ]
            : [
                _c("div", { staticClass: "login-info" }, [
                  _vm._v(
                    " To reset your password, enter your account ID below."
                  ),
                  _c("br"),
                  _c("br"),
                  _vm._v(" If you are a client, please use your NDIS number."),
                  _c("br"),
                  _vm._v(
                    " If you are a client manager, please use your email address. "
                  ),
                ]),
                _c(
                  "div",
                  { staticClass: "el-form-group" },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { prop: "email" } },
                      [
                        _c(
                          "span",
                          { staticClass: "svg-container svg-container_login" },
                          [_c("svgicon", { attrs: { name: "user" } })],
                          1
                        ),
                        _c("el-input", {
                          attrs: {
                            name: "email",
                            type: "text",
                            "auto-complete": "on",
                            placeholder: "NDIS number or email",
                          },
                          model: {
                            value: _vm.form.email,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "email", $$v)
                            },
                            expression: "form.email",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-button",
                  {
                    staticStyle: { width: "100%" },
                    attrs: { type: "primary", loading: _vm.loading },
                    on: { click: _vm.handleSubmit },
                  },
                  [_vm._v(" Reset Password ")]
                ),
                _c("div", { staticClass: "footer-link" }, [
                  _c("a", { attrs: { href: "/login" } }, [_vm._v("Cancel")]),
                ]),
              ],
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }