<template>
    <div class="forgot-container">
        <el-form
            ref="form"
            auto-complete="on"
            :model="form"
            :rules="rules"
            label-position="left"
            label-width="0px"
            class="card-box login-form"
        >
            <div class="logo">
                <img :src="logo" />
            </div>

            <template v-if="reset_password">
                <div>
                    <div class="message">
                        If a valid account was found, an email confirming
                        your<br />
                        reset password will be sent to your email address.
                    </div>
                    <div class="footer-link">
                        <a href="/login">Return to login</a>
                    </div>
                </div>
            </template>
            <template v-else>
                <div class="login-info">
                    To reset your password, enter your account ID below.<br /><br />
                    If you are a client, please use your NDIS number.<br />
                    If you are a client manager, please use your email address.
                </div>

                <div class="el-form-group">
                    <el-form-item prop="email">
                        <span class="svg-container svg-container_login">
                            <svgicon name="user" />
                        </span>
                        <el-input
                            v-model="form.email"
                            name="email"
                            type="text"
                            auto-complete="on"
                            placeholder="NDIS number or email"
                        />
                    </el-form-item>
                </div>
                <el-button
                    type="primary"
                    style="width: 100%"
                    :loading="loading"
                    @click="handleSubmit"
                >
                    Reset Password
                </el-button>

                <div class="footer-link">
                    <a href="/login">Cancel</a>
                </div>
            </template>
        </el-form>
    </div>
</template>

<script>
import {isValidEmail, validateNDISNumber} from '@/utils/validate';
import logo from '@/assets/logo_dark.svg';
import firebase from 'firebase/app';
import auth from '@/utils/auth';
import axios from 'axios';
import * as Sentry from '@sentry/browser';
import {API_CODES} from '@/utils/consts';

export default {
    name: 'forgot',
    data() {
        const validateEmail = (rule, value, callback) => {
            // check if a valid email address
            this.using_email = true;
            if (!isValidEmail(value)) {
                this.using_email = false;
                // check if a valid NDIS number
                if (!validateNDISNumber(value)) {
                    callback(new Error('Invalid email or NDIS number'));
                    return;
                }
            }
            callback();
        };

        return {
            logo,
            form: {
                email: '',
            },
            rules: {
                email: [
                    {required: true, trigger: 'blur', validator: validateEmail},
                ],
            },
            loading: false,
            using_email: true,
            reset_password: false,
        };
    },
    methods: {
        validateForm() {
            // turn the validation callback into a promise
            return new Promise((resolve, reject) => {
                console.log('Validate...');
                this.$refs.form.validate((valid) => {
                    resolve(valid);
                });
            });
        },
        async handleSubmit() {
            const valid = await this.validateForm();
            if (valid) {
                this.loading = true;
                let postdata = {ndis_number: this.form.email};
                if (this.using_email) {
                    postdata = {email: this.form.email};
                }
                axios
                    .post(
                        `${process.env.VUE_APP_BASE_URI}/user/password/reset`,
                        postdata
                    )
                    .then((response) => {
                        this.reset_password = true;
                    })
                    .catch((e) => {
                        this.reset_password = false;
                        this.$notify.error({
                            title: 'Error',
                            message:
                                e.response?.data?.data?.message ?? e.message,
                        });

                        Sentry.captureException(e);
                    })
                    .finally(() => {
                        this.loading = false;
                    });
            }
        },
        handleCancel() {},
    },
};
</script>

<style rel="stylesheet/scss" lang="scss">
.forgot-container {
    position: fixed;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    margin: 0 auto;
    background-color: $grey;

    .svg-container {
        padding: 6px 5px 6px 15px;
        vertical-align: middle;
        width: 30px;
        display: inline-block;

        &_login {
            font-size: 20px;
        }
    }

    .login-form {
        margin-top: 200px;
        position: relative;
        width: 500px;
        padding: 0 35px;
        overflow: hidden;

        .logo {
            top: 0;
            left: 0;
            width: 100%;
            height: 80px;
            display: flex;
            justify-content: center;
            align-items: center;
            img {
                width: 250px;
            }
        }
        .login-info {
            font-size: 13px;
            margin-top: 20px;
        }

        .el-form-group {
            padding: 40px 0;

            .el-form-item {
                border: 1px solid rgba(0, 0, 0, 0.25);
                border-radius: 5px;
                background: white;
                color: #000000;

                &:last-of-type {
                    margin-bottom: 0;
                }

                .el-form-item__content {
                    display: flex;

                    .el-input {
                        height: 52px;
                        width: 85%;
                        display: flex;
                        justify-content: center;
                        align-items: center;

                        input:-webkit-autofill {
                            -webkit-box-shadow: 0 0 0px 1000px white inset !important;
                            -webkit-text-fill-color: #000000 !important;
                        }

                        input {
                            background: transparent;
                            border: 0px;
                            -webkit-appearance: none;
                            border-radius: 0px;
                            color: #000000;
                            height: 47px;
                        }
                    }

                    .show-pwd {
                        position: absolute;
                        right: 10px;
                        top: 7px;
                        font-size: 16px;
                        color: #000000;
                        cursor: pointer;
                        user-select: none;
                    }
                }
            }
        }
    }

    .thirdparty-button {
        position: absolute;
        right: 35px;
        bottom: 28px;
    }

    .message {
        text-align: center;
        font-size: 14px;
        margin: 20px 0;
    }

    .footer-link {
        text-align: center;
        padding: 20px 0;
        font-size: 12px;
        color: $purple_dark;
    }
}
</style>
